import HeaderImage from '../../assets/header.jpg'
import data from './data'
import './header.css'

const Header = () => {
    return (
        <header id="header">
            <div className="container header__container">
                <div className="header__profile">
                    <img src={HeaderImage} alt="Header Portrait" />
                </div>
                
                <div className="header__socials">
                    {
                        data.map(i => <a key={i.id} href={i.link} target="_blank" rel="noopener noreferrer">{i.icon}</a>)
                    }
                </div>

                <h3>Jonathan Kamis</h3>
                <p>Software Engineer/DevOps Engineer</p>
                

                <div className="header__cta">
                    {/*<a href = "#contact" className="btn primary">Lets Talk</a>*/}
                    <a href="#portfolio" className="btn light">My Work</a>
                </div>
            </div>
        </header>
    )
}

export default Header