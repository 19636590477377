const data = [
    {
        id: 1,
        company: "United States Steel",
        dates: "June 2020 - November 2023",
        title: "Software Engineer",
        duties: [
            {
                id: 1,
                value: "Designed and developed complex calculation logic which processed thousands of timecards each week, appropriately divided time into billing categories, and submitted results to the payroll system",
            },
            {
                id: 2,
                value: "Designed and developed custom testing frameworks for any overly complex areas of the application, including PTO calculations and timecard calculations",

            },
            {
                id: 3,
                value: "Architected, designed, and developed backend microservice to handle PTO calculations",
            },
            {
                id: 4,
                value: "Assisted with the design of microservice architecture and later took ownership of managing microservices for the entire application",
            },
            {
                id: 5,
                value: "Entrusted with design and implementation of Oracle Database tables and all PL/SQL triggers and jobs for the entire application",
            },
            {
                id: 6,
                value: "Served as the primary engineer for debugging any critical production failures in a timely and consistent fashion"
            }
        ],
    },
    {
        id: 2,
        company: "United States Steel",
        dates: "June 2020 - November 2023",
        title: "DevOps Engineer",
        duties: [
            {
                id: 1,
                value: "Built and maintained over 100 CI/CD pipelines across the entire IT organization utilizing Azure DevOps",
            },
            {
                id: 2,
                value: "Designed template system for all pipelines to dramatically reduce maintenance organization wide",
            },
            {
                id: 3,
                value: "Assisted with containerization of over 100 legacy applications",
            },
            {
                id: 4,
                value: "Integrated SonarQube and NexusIQ code quality scans into all build pipelines"
            },
            {
                id: 5,
                value: "Created tooling to facilitate deployments of over 100 applications to Docker Swarm and later RedHat OpenShift"
            },
            {
                id: 6,
                value: "Assisted platform team with design and implementation of container architecture in both Docker Swarm and RedHat OpenShift"
            },
        ],
    },
    {
        id: 2,
        company: "UPMC",
        dates: "November 2023 - August 2024",
        title: "DevOps Engineer",
        duties: [
            {
                id: 1,
                value: "Responsible for enabling dozens of teams across the IT organization with DevOps best practices and tooling",
            },
            {
                id: 2,
                value: "Developed in-house CLI tooling using Go to make CI/CD processes smoother and more widely available to internal users"
            },
            {
                id: 3,
                value: "Converted legacy manual pipelines to cutting edge CI/CD pipelines"
            },
            {
                id: 4,
                value: "Automated manual deployment processes through CLI tooling and pipelines"
            },
            {
                id: 5,
                value: "Worked with Azure portal and Azure DevOps"
            }
        ],
    },
    {
        id: 4,
        company: "Chik Fil A",
        dates: "August 2024 - Present",
        title: "Software Engineer III",
        duties: [
            {
                id: 1,
                value: "Served as a full stack software engineer developing an invoicing system designed to eliminate thousands of pounds of paper invoicing each month through a new digital system."
            },
            {
                id: 2,
                value: "Developed event-driven, asynchronous processors using Fabrik and AWS CloudFormation",
            },
            {
                id: 3,
                value: "Developed processors using Amazon Textract to process invoices then predict and populate values to save user time"
            },
            {
                id: 4,
                value: "Developed multiple backend API endpoints in a Java Spring Boot framework"
            },
            {
                id: 5,
                value: "Developed frontend components using React with Typescript"
            },
            {
                id: 6,
                value: "Built and maintained application dashboards covering dozens of application metrics using DataDog"
            }
        ],
    },
]

export default data