import Scrollspy from 'react-scrollspy'
import './floating-nav.css'
import Nav from './Nav'
import data from './data'

const FloatingNav = () => {
    return (
        <ul id="floating__nav">
            <Scrollspy
                offset={-400}
                className="scrollspy"
                items={['header', 'about', 'expertise', 'experience', 'portfolio', 'testimonials']}
                currentClassName="active"
            >
                {
                data.map(item => <Nav key={item.id} item={item}/>)
            }
            </Scrollspy>
        </ul>
    )
}

export default FloatingNav