import Card from '../../components/Card'
import data from './data'
import './expertise.css'

const Services = () => {
    return (
        <section id = "expertise">
            <h2>My Expertise</h2>
            <p></p>
            <div className="container services__container">
                {
                    data.map(i => (
                        <Card key={i.id} className="service light">
                            <div className="service__icon">{i.icon}</div>
                            <div className="service__details">
                                <h4>{i.title}</h4>
                                <p>{i.desc}</p>
                            </div>
                        </Card>
                    ))
                }
            </div>
        </section>
    )
}

export default Services