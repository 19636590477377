import { AiFillAppstore, AiFillHome, AiFillMessage } from "react-icons/ai";
import { BsPersonFill } from "react-icons/bs";
import { MdHomeRepairService } from "react-icons/md";
import { PiEyeglassesLight } from "react-icons/pi";

const data = [
    {id: 1, link: '#', icon: <AiFillHome/>},
    {id: 2, link: '#about', icon: <BsPersonFill/>},
    {id: 3, link: '#expertise', icon: <PiEyeglassesLight/>},
    {id: 4, link: '#experience', icon: <MdHomeRepairService/>},
    {id: 5, link: '#portfolio', icon: <AiFillAppstore/>},
    {id: 6, link: '#testimonials', icon: <AiFillMessage/>}
]

export default data;