import Logo from '../../assets/logo.png'
import data from './data'
import './navbar.css'

const Navbar = () => {
    return (
        <nav>
            <div className="container nav__container">
                <a href="https://kamis.dev" className='nav__logo'>
                    <img src={Logo} alt="Logo"/>
                </a>
                <ul className="nav__menu">
                    {
                        data.map(i => <li key={i.id}><a href={i.link}>{i.title}</a></li>)
                    }
                </ul>
            </div>
        </nav>
    )
}

export default Navbar