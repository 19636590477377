import { AiFillGithub, AiOutlineLinkedin } from "react-icons/ai";

export const links = [
    { id: 1, link: '#', title: 'Home' },
    { id: 2, link: '#about', title: 'About' },
    { id: 3, link: '#expertise', title: 'Experiences' },
    { id: 4, link: '#portfolio', title: 'Portfolio' },
    { id: 5, link: '#testimonials', title: 'Testimonials' }
];

export const socials = [
    {id: 1, link: 'https://www.linkedin.com/in/jonathan-kamis', icon: <AiOutlineLinkedin/>},
    {id: 2, link: 'https://github.com/jon-kamis', icon: <AiFillGithub/>}
];