import './portfolio.css'
import ProjectsCategories from './ProjectsCategories'
import Projects from './Projects'
import data from './data'
import { useState } from 'react'

const Portfolio = () => {
    const [projects, setProjects] = useState(data);
    const categories = ["all", ...new Set(data.map(i => i.category))];
    
    const filterProjectsHandler = (category) => {

        clearProjects();

        if(category === "all") {
            setProjects(data);
            return;
        }

        const filterProjects = data.filter(project => project.category === category);
        setProjects(filterProjects);
    }

    const clearProjects = () => {
        setProjects([]);
    }

    return (
        <section id="portfolio">
            <h2>Recent Projects</h2>
            <p>
                Check out some of the projects I recently worked on
            </p>
            <div className="container portfolio__container">
                <ProjectsCategories categories={categories} onFilterProjects={filterProjectsHandler}/>
                <Projects projects={projects} />
            </div>
        </section>
    )
}

export default Portfolio