import data from "./data";
import Card from "../../components/Card";
import "./experience.css"

const Experiences = () => {
    return (
        <section id = "experience">
            <h2>Professional Experience</h2>
            <p></p>
            <div className="container experiences__container">
                {
                    data.map(i => (
                        <Card key={i.id} className="experience">
                            <div className="experience__header">
                                <h2>{i.title}</h2>
                                <h3>{i.company}</h3>
                                <p><i><b>{i.dates}</b></i></p>
                            </div>

                            <ul className="experience__list">
                                {
                                    i.duties.map(duties => (
                                        <li id={duties.id}><p><b>{duties.value}</b></p></li>
                                    ))
                                }
                            </ul>
                        </Card>
                    ))
                }
            </div>
        </section>
    )
}

export default Experiences