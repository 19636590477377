import AboutImage from '../../assets/about.jpg'
import Resume from '../../assets/kamis-resume.pdf'
import { HiDownload } from 'react-icons/hi'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
    return (
        <section id="about">
            <div className="container about__container">
                <div className="about__left">
                    <div className="about__portrait">
                        <img src={AboutImage} alt="About Portrait"/>
                    </div>
                </div>
                <div className="about__right">
                    <h2>About Me</h2>
                    <div className="about__cards">
                        {
                            data.map(i => (
                                <Card key={i.id} className="about__card">
                                    <span className="about__card-icon">{i.icon}</span>
                                    <h5>{i.title}</h5>
                                    <small>{i.desc}</small>
                                </Card>
                            ))
                        }
                    </div>
                    <p>
                        Hi, my name is Jonathan Kamis from Pittsburgh, PA. I'm a full stack software engineer and a DevOps engineer with over 4 years of experience working for fortune 500 companies.
                    </p>
                    <p>
                        I am passionate about building high performance applications that are easy for users to interact with.
                        On the software engineer side, I have a lot of experience with both Java Spring Boot and Go. I am primarily a backend engineer but I have recently began work as a full stack engineer working primarily with React.
                        I also have a lot of platform and DevOps experience. I have set up end-to-end deployment systems using Azure DevOps, Docker, RedHat OpenShift, and limited Kubernetes.

                        I have worked with both Azure and AWS.

                        I am also very familiar with Microservice Architecture, having built and managed multiple microservices myself for high demand applications.
                    </p>
                    <a href={Resume} download className='btn primary'>Download Resume <HiDownload/></a>
                </div>
            </div>
        </section>
    )
}

export default About