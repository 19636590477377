import './testimonials.css'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';
import data from './data.js'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

//Import required modules
import { Pagination } from 'swiper/modules';
import Testimonial from './Testimonial.js';

const Testimonials = () => {
    return (
        <section id="testimonials">
            <h2>What my peers say</h2>
            <p>
                These are unbiased testimonials from some of my peers
            </p>
            <div className="container">
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                    autoplay={{
                        delay: 10000,
                        disableOnInteraction: true,
                    }}
                    className="mySwiper"
                >
                    {
                        data.map(testimonial => (
                            <SwiperSlide key={testimonial.id}>
                                <Testimonial testimonial={testimonial}/>
                            </SwiperSlide>
                        ))
                    }

                </Swiper>
            </div>
        </section>
    )
}

export default Testimonials