import Navbar from './sections/navbar/Navbar';
import Header from './sections/header/Header';
import About from './sections/about/About';
import Experiences from './sections/experience/Experience'
import Portfolio from './sections/portfolio/Portfolio';
import Testimonials from './sections/testimonials/Testimonials';
import Footer from './sections/footer/Footer';
import FloatingNav from './sections/floating-nav/FloatingNav';
import Theme from './theme/Theme';
import { useThemeContext } from './context/theme-context';
import {useRef, useState, useEffect} from 'react';
import Expertise from "./sections/expertise/Expertise";

const App = () => {
    const mainRef = useRef();
    const {themeState} = useThemeContext();

    const [showFloatingNav, setShowFloatingNav] = useState(true);
    const [siteYPosition, setSiteYPosition] = useState(0);

    const showFloatingNavHandler = () => {
        setShowFloatingNav(true);
    }

    const hideFloatingNavHandler = () => {
        setShowFloatingNav(false);
    }

    //Check if floating nav should be shown or hidden
    const floatingNavToggleHandler = () => {
        //check if we scrolled up or down at least 20px
        if(siteYPosition < (mainRef?.current?.getBoundingClientRect().y - 20) 
            || siteYPosition > (mainRef?.current?.getBoundingClientRect().y + 20)) {
                showFloatingNavHandler();
        } else {
            hideFloatingNavHandler();
        }

        setSiteYPosition(mainRef?.current?.getBoundingClientRect().y);
    }

    useEffect(() => {
        const checkYPosition = setInterval(floatingNavToggleHandler, 2000);

        //cleanup
        return () => clearInterval(checkYPosition);

    }, [siteYPosition])

    return (
        <main className={`${themeState.primary} ${themeState.background}`}  ref={mainRef}>
            <Navbar/>
            <Header/>
            <About/>
            <Expertise/>
            <Experiences/>
            <Portfolio/>
            <Testimonials/>
            <Footer/>
            {showFloatingNav && <FloatingNav/>}
            <Theme/>
        </main>
    )
}

export default App