import Card from "../../components/Card"
import { IoPersonSharp } from "react-icons/io5";

const Testimonial = ({testimonial}) => {
  return (
    <Card className="light testimonial">
        <p>{testimonial.quote}</p>
        <div className="testimonial__client">
            <div className="testimonial__client-avatar">
                {
                    testimonial.avatar ? <img src={testimonial.avatar} alt="Testimonal Avatar" /> : <IoPersonSharp/> 
                }
                
            </div>
            <div className="testimonial__client-details">
                <h6>{testimonial.name}</h6>
                <small>{testimonial.profession}</small>
            </div>
        </div>
    </Card>
  )
}

export default Testimonial