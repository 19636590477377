
const data = [
    {
        id: 1,
        quote: <p>I worked closely with Jon in his years at USS on a few different high priority projects and initiatives. When it comes to Application Development, Jon is an expert when it comes to understanding requirements as well as coming up with well thought out solutions. I relied on Jon extensively when it came to working with stakeholders and laying out the pros and cons of different solutions. His work was done on time or ahead of schedule and you could always count on it being well tested and production ready.<br/><br/>Jon was also our teams DevOps engineer, but this role extended throughout the entire company. His ability to learn and come up with solutions made him an indispensable asset to the companies DevOps initiatives. Any issues or requirements that arose in the CI/CD space, you could be sure that he would come up with a solution. I cannot even begin to estimate the amount of time his work had saved us over the years.<br/><br/>He would be an asset to any team, and you can be sure that the team will benefit greatly by having access to his wealth of experience and knowledge.</p>,
        name: "Steve",
        profession: "Senior Frontend Software Engineer",
    },
    {
        id: 2,
        quote: <p>I really enjoy working with Jon! He is genuinely curious about programming and has a strong desire to learn and figure things out. He picks things up very quickly and is really good at communicating whether that is asking for help, raising concern for a new bug, or making suggestions on how to improve our pipelines and features. I've seen him go above and beyond on multiple stories, surpassing the requirements needed to get through UAT. He is a very talented and well rounded engineer that has made a big impact on our team!</p>,
        name: "Zachary",
        profession: "Principal Software Engineer",
    }
]

export default data;